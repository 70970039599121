import http from '@/config/axios.config'

// 得到图层首页信息
export function getLayersTree() {
    return http({
        url: `/rest/betaGISUI/layers/tree`,
        method: "get",
    })
}

// 得到单个图层信息
export function getLayerResourceByName(layerName) {
    return http({
        url: `/rest/layersResource/${layerName}`,
        method: "get",
    })
}

// 发布矢量图层
export function publishVectorLayer(workspaceName, storeName, data) {
    return http({
        url: `/rest/workspaces/${workspaceName}/datastores/${storeName}/featuretypes`,
        method: "post",
        data: data
    })
}
// 发布栅格图层
export function publishRasterLayer(workspaceName, storeName, data) {
    return http({
        url: `/rest/workspaces/${workspaceName}/coveragestores/${storeName}/coverages`,
        method: "post",
        data: data
    })
}

// 修改矢量图层
export function putVectorLayer(workspaceName, storeName, layerName, data) {
    return http({
        url: `/rest/workspaces/${workspaceName}/datastores/${storeName}/featuretypes/${layerName}`,
        method: "put",
        data: data
    })
}
// 修改栅格图层
export function putRasterLayer(workspaceName, storeName, layerName, data) {
    return http({
        url: `/rest/workspaces/${workspaceName}/coveragestores/${storeName}/coverages/${layerName}`,
        method: "put",
        data: data
    })
}

// 删除矢量图层
export function delVectorLayer(workspaceName, layerName) {
    return http({
        url: `/rest/workspaces/${workspaceName}/layers/${layerName}?recurse=true`,
        method: "delete",
    })
}
// 删除栅格图层
export function delRasterLayer(workspaceName, layerName) {
    return http({
        url: `/rest/workspaces/${workspaceName}/layers/${layerName}?recurse=true`,
        method: "delete",
    })
}

// 得到切图图层信息
export function getGWCLayerInfo(layerName) {
    return http({
        url: `/gwc/rest/layers/${layerName}`,
        method: "get",
    })
}
// 添加图层到切图图层或更新图层
export function addGWCLayer(layerName, data) {
    return http({
        url: `/gwc/rest/layers/${layerName}`,
        method: "put",
        data: data
    })
}
// 删除切图图层
export function delGWCLayer(layerName) {
    return http({
        url: `/gwc/rest/layers/${layerName}`,
        method: "delete",
    })
}
// 进行切图动作
export function execGWCTask(layerName, data) {
    return http({
        url: `/gwc/rest/seed/${layerName}.json`,
        method: "post",
        data: data
    })
}