<template>
  <a-modal
    :visible="selectionVisible"
    @ok="showPublishPage"
    @cancel="cancel"
    okText="确认"
    cancelText="取消"
  >
    <h2>请选择要发布的数据存储</h2>
    <a-select
      v-model="id"
      style="
        height: 28px;
        background: #ffffff;
        border: 1px solid #dcdcdc;
        border-radius: 4px;
      "
      @change="setStore"
    >
      <a-select-option
        v-for="store in stores"
        :key="store.id"
        :value="store.id"
        >{{ store.showName }}</a-select-option
      >
    </a-select>
  </a-modal>
</template>
<script>
import { getStoresTree } from "@/api/data/store.js";

export default {
  data() {
    return {
      selectedStore: {},
      stores: [],
      id: "",
    };
  },
  watch: {
    selectionVisible() {
      if (this.selectionVisible && this.stores.length == 0) {
        this.$message.error("请先至少创建一个数据存储");
        this.cancel();
      }
    },
  },
  methods: {
    showPublishPage() {
      this.$emit("update:selectionVisible", false);
      this.$emit("update:selectedData", this.selectedStore);
      this.$emit("update:publishVisible", true);
    },
    cancel() {
      this.$emit("update:selectionVisible", false);
      this.$emit("update:selectedStore", null);
    },
    setStore(value) {
      this.selectedStore = this.stores.find((item) => item.id == value);
    },
    refreshStore() {
      getStoresTree().then((res) => {
        this.stores = res.data.map((item) => {
          item.showName = item.workspace + `:` + item.name;
          return item;
        });
        if (this.stores.length > 0) {
          this.selectedStore = this.stores[0];
          this.id = this.stores[0].id;
        }
      });
    },
  },
  props: ["selectionVisible", "publishVisible", "selectedData"],
  mounted() {
    this.refreshStore();
  },
};
</script>