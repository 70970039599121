<template>
  <div class="layers">
    <div class="title"></div>
    <div class="crumb"></div>
    <div class="content">
      <div class="actions">
        <div class="search">
          <a-input-search
            class="search-input"
            placeholder="请输入您要查找的内容"
            v-model="searchName"
            @search="onSearch"
          />
        </div>
        <div class="operation">
          <a-button
            class="add"
            type="primary"
            @click="showLayerDialog(`new`, null)"
            >添加新的图层</a-button
          >
          <a-button class="delete" type="danger" @click="showDelDialog"
            >删除所选图层</a-button
          >
        </div>
      </div>
      <a-card class="table">
        <a-table
          :columns="columns"
          :data-source="showData"
          :row-selection="rowSelection"
        >
          <span slot="type" slot-scope="text">
            <div>{{ text | transDataTypeName }}</div>
          </span>
          <span slot="showName" slot-scope="showName, record">
            <a @click="showLayerDialog(`edit`, record)">{{ showName }}</a>
          </span>
          <span slot="store" slot-scope="store">
            <div>{{ store }}</div>
          </span>
          <span slot="wmsLink" slot-scope="record">
            <a :href="record + '&format=application/openlayers'" target="_blank"
              >OpenLayers</a
            >
          </span>
          <span slot="allFormats" slot-scope="link">
            <a-select
              v-model="selectedFormat"
              style="
                width: 166px;
                height: 28px;
                background: #ffffff;
                border: 1px solid #dcdcdc;
                border-radius: 4px;
              "
              @change="open(selectedFormat)"
            >
              <a-select-option
                v-for="format in link"
                :key="format.value"
                :value="format.value"
                >{{ format.text }}</a-select-option
              >
            </a-select>
          </span>
          <span slot="gwc" slot-scope="dataStyle, record">
            <a-button
              type="primary"
              shape="round"
              @click="showGWCDialog(record)"
              >操作</a-button
            >
            <a-button shape="round" type="danger" style="margin-left: 6px"
              >清空</a-button
            >
          </span>
        </a-table>
      </a-card>
    </div>
    <store-selection
      :selectedData.sync="selectedData"
      :selectionVisible.sync="selectionVisible"
      :publishVisible.sync="publishVisible"
    ></store-selection>
    <layer-publish
      :publishVisible.sync="publishVisible"
      :selectedData.sync="selectedData"
      :editData="editData"
      @refreshLayers="refreshLayers"
    ></layer-publish>
    <GWC-seed-form
      :gwcVisible.sync="gwcVisible"
      :layerData.sync="seedData"
    ></GWC-seed-form>
    <a-modal
      v-model="delVisible"
      title="提示"
      ok-text="确认"
      cancel-text="取消"
      @ok="deleteSelectedLayers"
      @cancel="hideDelDialog"
    >
      <p>确认删除选中的图层吗？</p>
    </a-modal>
  </div>
</template>
<script>
const columns = [
  {
    title: "类型",
    width: 100,
    dataIndex: "type",
    key: "type",
    scopedSlots: { customRender: "type" },
  },
  {
    title: "图层名称",
    dataIndex: "showName",
    key: "showName",
    scopedSlots: { customRender: "showName" },
  },
  {
    title: "存储",
    dataIndex: "store",
    key: "store",
    scopedSlots: { customRender: "store" },
  },
  {
    title: "空间参考系统",
    dataIndex: "srs",
    key: "srs",
  },
  {
    title: "预览",
    dataIndex: "wmsLink",
    key: "wmsLink",
    scopedSlots: { customRender: "wmsLink" },
  },
  {
    title: "所有输出格式",
    dataIndex: "linkMap",
    key: "allFormats",
    scopedSlots: { customRender: "allFormats" },
  },
  {
    title: "切图",
    width: 300,
    dataIndex: "style",
    key: "gwc",
    scopedSlots: { customRender: "gwc" },
  },
];

const wmsFormats = [
  { text: "GeoPackage", value: "gpkg" },
  { text: "GeoTiff", value: "image/tiff" },
  { text: "KML", value: "kml" },
  { text: "KMZ", value: "kmz" },
  { text: "PDF", value: "application/pdf" },
  { text: "PNG", value: "image/png" },
  { text: "SVG", value: "image/svg" },
];

const wfsFormats = [
  { text: "GeoJSON", value: "application/json" },
  { text: "CSV", value: "csv" },
  { text: "Excel(.xls)", value: "excel" },
  { text: "Excel 2007(.xlsx)", value: "excel2007" },
  { text: "Shapefile", value: "SHAPE-ZIP" },
];

import {
  getLayersTree,
  delVectorLayer,
  delRasterLayer,
} from "@/api/data/layer.js";
import StoreSelection from "./StoreSelection";
import LayerPublish from "./LayerPublish";
import GWCSeedForm from "./GWCSeedForm";
import baseUrl from "@/config/baseurl.js";

export default {
  data() {
    return {
      searchName: null,
      data: [],
      showData: [],
      columns,
      selectedRows: [],
      selectionVisible: false,
      publishVisible: false,
      gwcVisible: false,
      delVisible: false,
      editData: null,
      selectedData: null,
      selectedFormat: "-请选择-",
      seedData: null,
    };
  },
  methods: {
    onSearch() {
      if (this.searchName && this.searchName !== "") {
        this.showData = this.data.filter((p) => {
          return (
            p.type.indexOf(this.searchName) !== -1 ||
            p.showName.indexOf(this.searchName) !== -1 ||
            p.store.indexOf(this.searchName) !== -1 ||
            p.srs.indexOf(this.searchName) !== -1
          );
        });
      } else {
        this.refreshLayers();
      }
    },
    open(val) {
      this.selectedFormat = "-请选择-";
      window.open(val);
    },
    showLayerDialog(type, data) {
      if (type == `edit`) {
        this.editData = data;
        this.publishVisible = true;
      } else {
        this.editData = null;
        this.selectionVisible = true;
      }
    },
    showDelDialog() {
      if (this.selectedRows.length == 0) {
        this.$message.info("未选择任何图层");
      } else {
        this.delVisible = true;
      }
    },
    hideDelDialog() {
      this.delVisible = false;
    },
    deleteSelectedLayers() {
      this.selectedRows.map((item) => {
        if (item.type == "VECTOR") {
          delVectorLayer(item.workspace, item.name).then((res) => {
            if (res.status == 200) {
              this.$message.success(`已删除图层${item.name}`);
            }
            this.refreshLayers();
          });
        } else if (item.type == "RASTER") {
          delRasterLayer(item.workspace, item.name).then((res) => {
            if (res.status == 200) {
              this.$message.success(`已删除图层${item.name}`);
            }
            this.refreshLayers();
          });
        }
      });
      this.hideDelDialog();
    },
    showGWCDialog(record) {
      this.gwcVisible = true;
      this.seedData = record;
    },
    refreshLayers() {
      // console.log(window.sessionStorage.getItem("token"));
      getLayersTree().then((res) => {
        this.data = res.data.map((item) => {
          item.key = item.id;
          item.showName = item.workspace + `:` + item.name;
          item.wmsLink = this.replaceUrl(item.wmsLink);
          item.wfsLink = this.replaceUrl(item.wfsLink);
          this.replaceUrl(item.wfsLink);
          if (item.type == "VECTOR") {
            item.linkMap = wmsFormats
              .map((format) => {
                return {
                  text: format.text,
                  value: item.wmsLink + "&format=" + format.value,
                };
              })
              .concat(
                wfsFormats.map((format) => {
                  var val = item.wfsLink + "&outputFormat=" + format.value;
                  if (format.value == "SHAPE-ZIP") {
                    val = val + "&format_options=CHARSET:UTF-8";
                  }
                  return {
                    text: format.text,
                    value: val,
                  };
                })
              );
          } else if (item.type == "RASTER") {
            item.linkMap = wmsFormats.map((format) => {
              return {
                text: format.text,
                value: item.wmsLink + "&format=" + format.value,
              };
            });
          }
          return item;
        });
        this.showData = this.data;
      });
    },
    replaceUrl(url) {
      return url
        .replace("localhost:8080", window.location.host)
        .replace("/geoserver", baseUrl);
    },
  },
  computed: {
    rowSelection() {
      return {
        onChange: (selectedRowKeys, selectedRows) => {
          this.selectedRows = selectedRows;
        },
      };
    },
  },
  components: { StoreSelection, LayerPublish, GWCSeedForm },
  mounted() {
    this.refreshLayers();
    setTimeout(() => {
      if (this.$route.query.add) {
        this.showLayerDialog(`new`, null);
      }
    }, 300);
  },
};
</script>

<style scoped>
.actions {
  display: flex;
  justify-content: space-between;
  margin-bottom: 26px;
}
.search-input {
  width: 505px;
  height: 48px;
  background: #ffffff;
  border-radius: 24px;
}

.add,
.delete {
  width: 163px;
  height: 48px;
  border-radius: 4px;
  margin-right: 19px;
}
</style>
<style>
.layers .search-input .ant-input {
  width: 505px;
  height: 48px;
  background: #ffffff;
  border-radius: 24px;
}

.layers .search-input .ant-input-suffix {
  font-size: 18px;
}
</style>
