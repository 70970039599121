<template>
  <a-modal
    :visible="publishVisible"
    @ok="publishOrUpdateLayer"
    @cancel="close"
    okText="确认"
    cancelText="取消"
  >
    <a-form :form="form">
      <h2>图层信息</h2>
      <a-form-item label="名称">
        <a-input :maxLength="20" v-decorator="['name', ValidateRules.name]" />
      </a-form-item>
      <a-form-item label="摘要">
        <a-textarea v-model="layerParam.abstract" :rows="4" />
      </a-form-item>
      <h2>坐标参考系统</h2>
      <!-- <a-form-item label="原始坐标参考系统">
        <a-input v-model="layerParam.nativeCRS" />
      </a-form-item> -->
      <div style="display: flex; justify-content: space-between">
        <a-form-item label="定义空间参考系统" style="display: flex">
          <a-input :maxLength="30" v-model="layerParam.srs" />
        </a-form-item>
        <a-button type="primary" @click="showSRSSelectionDialog">查找</a-button>
      </div>
    </a-form>
    <srs-selection
      :srsSelectionVisible.sync="srsSelectionVisible"
      @setSRSCode="setSRSCode"
    ></srs-selection>
  </a-modal>
</template>
<script>
import {
  publishVectorLayer,
  publishRasterLayer,
  putVectorLayer,
  putRasterLayer,
} from "@/api/data/layer.js";
import srsSelection from "../common/srsSelection";

export default {
  components: { srsSelection },
  data() {
    return {
      form: this.$form.createForm(this),
      srsSelectionVisible: false,
      layerParam: {
        name: "",
        abstract: "",
        srs: "EPSG:4326",
      },
      ValidateRules: {
        name: { rules: [{ required: true, message: "请输入工作空间名称" }] },
      },
    };
  },
  methods: {
    publishOrUpdateLayer() {
      this.form.validateFields((err, values) => {
        if (!err) {
          if (this.editData) this.updateLayer(values);
          else this.publishLayer(values);
        }
      });
    },
    updateLayer(values) {
      if (this.editData.type == "VECTOR") {
        putVectorLayer(
          this.editData.workspace,
          this.editData.store,
          this.editData.name,
          {
            featureType: {
              name: values.name,
              abstract: this.layerParam.abstract,
              srs: this.layerParam.srs,
            },
          }
        ).then((res) => {
          if (res.status == 200) {
            this.$message.success("更新图层信息成功");
            this.close();
          }
        });
      } else if (this.editData.type == "RASTER") {
        putRasterLayer(
          this.editData.workspace,
          this.editData.store,
          this.editData.name,
          {
            coverage: {
              name: values.name,
              abstract: this.layerParam.abstract,
              srs: this.layerParam.srs,
            },
          }
        ).then((res) => {
          if (res.status == 200) {
            this.$message.success("更新图层信息成功");
            this.close();
          }
        });
      }
    },
    publishLayer(values) {
      if (this.selectedData.available[0].name == undefined) {
        this.$message.error(
          "无法获取该数据存储可发布的图层名。请检查数据存储重新上传。"
        );
        return;
      }
      if (this.selectedData.dataType == "VECTOR") {
        publishVectorLayer(
          this.selectedData.workspace,
          this.selectedData.name,
          {
            featureType: {
              name: values.name,
              nativeName: this.selectedData.available[0].name,
              abstract: this.layerParam.abstract,
              srs: this.layerParam.srs,
            },
          }
        ).then((res) => {
          if (res.status == 201) {
            this.$message.success("添加图层信息成功");
            this.close();
          }
        });
      } else if (this.selectedData.dataType == "RASTER") {
        publishRasterLayer(
          this.selectedData.workspace,
          this.selectedData.name,
          {
            coverage: {
              name: values.name,
              nativeName: this.selectedData.available[0].name,
              abstract: this.layerParam.abstract,
              srs: this.layerParam.srs,
            },
          }
        ).then((res) => {
          if (res.status == 201) {
            this.$message.success("添加图层信息成功");
            this.close();
          }
        });
      }
    },
    close() {
      this.$emit("update:publishVisible", false);
      this.$emit("refreshLayers");
      this.layerParam = {
        name: "",
        abstract: "",
        srs: "EPSG:4326",
      };
      this.form.setFieldsValue({
        name: "",
      });
    },
    showSRSSelectionDialog() {
      this.srsSelectionVisible = true;
    },
    setSRSCode(srs) {
      this.layerParam.srs = `EPSG:` + srs;
    },
  },
  props: ["publishVisible", "editData", "selectedData"],
  watch: {
    publishVisible() {
      if (this.publishVisible && this.editData) {
        this.layerParam = { ...this.editData };
        this.$nextTick(() => {
          this.form.setFieldsValue({
            name: this.layerParam.name,
          });
        });
      }
      if (this.publishVisible && this.selectedData) {
        this.layerParam.workspace = this.selectedData.workspace;
        this.layerParam.store = this.selectedData.name;
      }
    },
  },
  mounted() {},
};
</script>