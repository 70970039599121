<template>
  <a-modal
    :visible="gwcVisible"
    @ok="createTask"
    @cancel="cancel"
    okText="确认"
    cancelText="取消"
  >
    <a-form :form="form">
      <h2>切图选项</h2>
      <a-form-item label="切图方案" style="display: flex">
        <a-select
          v-model="selectedGridset"
          style="
            width: 166px;
            height: 28px;
            background: #ffffff;
            border-radius: 4px;
          "
        >
          <a-select-option
            v-for="data in gridsets"
            :key="data.name"
            :value="data.name"
            >{{ data.name }}</a-select-option
          >
        </a-select>
      </a-form-item>
      <a-form-item label="切图格式" style="display: flex">
        <a-select
          v-model="selectedImageFormat"
          style="
            width: 240px;
            height: 28px;
            background: #ffffff;
            border-radius: 4px;
          "
        >
          <a-select-option
            v-for="data in imageFormats"
            :key="data"
            :value="data"
            >{{ data }}</a-select-option
          >
        </a-select>
        <a-button
          style="height: 32px; margin-left: 10px; border-radius: 4px"
          @click="openPreviewWindow"
          >预览</a-button
        >
      </a-form-item>
      <a-form-item label="最小缩放等级" style="display: flex">
        <a-input-number
          v-model="minZoom"
          :min="1"
          :max="29"
          :default-value="1"
        />
      </a-form-item>
      <a-form-item label="最大缩放等级" style="display: flex">
        <a-input-number
          v-model="maxZoom"
          :min="1"
          :max="30"
          :default-value="18"
        />
      </a-form-item>
      <a-form-item label="执行类型" style="display: flex">
        <a-select
          v-model="selectedType"
          style="
            width: 166px;
            height: 28px;
            background: #ffffff;
            border: 1px solid #dcdcdc;
            border-radius: 4px;
          "
        >
          <a-select-option value="seed">构建缺失的瓦片</a-select-option>
          <a-select-option value="truncate">移除瓦片</a-select-option>
          <a-select-option value="reseed">重新构建所有瓦片</a-select-option>
        </a-select>
      </a-form-item>
    </a-form>
  </a-modal>
</template>
<script>
import { getGridsetsTree } from "@/api/data/gridsets.js";
import {
  getGWCLayerInfo,
  addGWCLayer,
  delGWCLayer,
  execGWCTask,
} from "@/api/data/layer.js";
import baseUrl from "@/config/baseurl.js";

export default {
  data() {
    return {
      form: this.$form.createForm(this),
      selectedGridset: "EPSG:4326",
      selectedImageFormat: "image/png",
      imageFormats: ["image/png", "image/jpeg"],
      selectedType: "seed",
      minZoom: 1,
      maxZoom: 18,
      gridsets: [],
    };
  },
  props: ["gwcVisible", "layerData"],
  methods: {
    openPreviewWindow() {
      this.checkLayerAndGridSets().then((res) => {
        if (res) {
          window.open(
            "http://" +
              window.location.host +
              baseUrl +
              "/gwc/demo/" +
              this.layerData.showName +
              "?gridSet=" +
              this.selectedGridset +
              "&format=" +
              this.selectedImageFormat
          );
        }
      });
    },
    createTask() {
      this.checkLayerAndGridSets().then((res) => {
        if (res) {
          execGWCTask(this.layerData.showName, {
            seedRequest: {
              gridSetId: this.selectedGridset,
              zoomStart: this.minZoom,
              zoomStop: this.maxZoom,
              format: this.selectedImageFormat,
              type: this.selectedType,
              threadCount: 1,
              parameters: {
                entry: {
                  string: ["STYLES", this.layerData.style],
                },
              },
            },
          }).then((res) => {
            if (res.status == 200) {
              this.$message.success(`已成功在后台创建切图任务`);
            }
            this.cancel();
          });
        }
      });
    },
    checkLayerAndGridSets() {
      return new Promise((resolve) => {
        // 首先判断是否在切图图层
        if (this.layerData.inGWCLayers) {
          // 判断切图方案是否在图层选项内, 如果不在则添加。
          getGWCLayerInfo(this.layerData.showName).then((res) => {
            if (res.status == 200) {
              console.log(res.data.GeoServerLayer);
              var originalGridSubsets = res.data.GeoServerLayer.gridSubsets;
              var originalMimeFormats = res.data.GeoServerLayer.mimeFormats;
              let subsetsFind = originalGridSubsets.find(
                (item) => item.gridSetName == this.selectedGridset
              );
              let formatsFind = originalMimeFormats.find(
                (item) => item == this.selectedImageFormat
              );
              if (subsetsFind && formatsFind) {
                return resolve(true);
              }
              var newGridSubsets = originalGridSubsets;
              var newMimeFormats = originalMimeFormats;
              if (!subsetsFind) {
                newGridSubsets = originalGridSubsets.concat({
                  gridSetName: this.selectedGridset,
                });
              }
              if (!formatsFind) {
                newMimeFormats = originalMimeFormats.concat(
                  this.selectedImageFormat
                );
              }
              console.log(newGridSubsets);
              console.log(newMimeFormats);
              this.createOrUpdateGWCLayer(
                newGridSubsets,
                newMimeFormats,
                false
              ).then((res) => {
                return resolve(res);
              });
            }
          });
        } else {
          this.createOrUpdateGWCLayer(
            [{ gridSetName: this.selectedGridset }],
            [this.selectedImageFormat],
            false
          ).then((res) => {
            return resolve(res);
          });
        }
      });
    },
    createOrUpdateGWCLayer(newGridSubsets, newMimeFormats, reBuilding) {
      return new Promise((resolve, reject) => {
        addGWCLayer(this.layerData.showName, {
          GeoServerLayer: {
            id: this.layerData.id,
            name: this.layerData.showName,
            enabled: true,
            inMemoryCached: true,
            mimeFormats: { string: newMimeFormats },
            metaWidthHeight: { int: [4, 4] },
            gridSubsets: {
              gridSubset: newGridSubsets,
            },
          },
        })
          .then((res) => {
            if (res.status == 200) {
              return resolve(true);
            }
          })
          .catch((error) => {
            // 如果是更新失败, 尝试补救, 方法为删除切图图层再添加。
            if (this.layerData.inGWCLayers && !reBuilding) {
              console.log("开始补救");
              this.reBuild().then((res) => {
                return resolve(res);
              });
            } else {
              this.$message.error(error);
              return reject(false);
            }
          });
      });
    },
    reBuild() {
      return new Promise((resolve, reject) => {
        delGWCLayer(this.layerData.showName).then((res) => {
          if (res.status == 200) {
            this.createOrUpdateGWCLayer(
              [{ gridSetName: this.selectedGridset }],
              [this.selectedImageFormat],
              true
            ).then((res) => {
              return resolve(res);
            });
          } else {
            return reject(false);
          }
        });
      });
    },
    cancel() {
      this.$emit("update:layerData", null);
      this.$emit("update:gwcVisible", false);
      this.selectedGridset = "EPSG:4326";
      this.selectedImageFormat = "image/png";
      this.imageFormats = ["image/png", "image/jpeg"];
      this.selectedType = "seed";
      this.minZoom = 1;
      this.maxZoom = 18;
    },
  },
  watch: {
    gwcVisible() {
      if (this.gwcVisible) {
        if (this.layerData.type && this.layerData.type == "VECTOR") {
          this.imageFormats.push("application/json;type=geojson");
        }
      }
    },
  },
  mounted() {
    console.log(window.sessionStorage.getItem("token"));
    getGridsetsTree().then((res) => {
      this.gridsets = res.data;
      if (this.gridsets.length > 0) {
        this.selectedGridset = this.gridsets[0].name;
      }
    });
  },
};
</script>